import React from "react";
import { ResponsiveType } from "../../interface/redux/config";

type Props = {
  responsive: ResponsiveType;
};

const CultureSection3 = ({
  responsive: { Desktop, Mobile, Tablet },
}: Props) => {
  return (
    <div className='text-white mt-[240px]'>
      <div className='font-bold nomb:text-[40px] mb:text-[30px] leading-[160%]'>
        휴컨은, <br />
        우리만의 Value{" "}
        <Mobile>
          <br />
        </Mobile>{" "}
        기반으로 성장합니다.
      </div>
      <div className='mt-[80px] grid gap-y-[48px]'>
        {cultureSection3Layouts.map(({ title1, title2, sub }, idx) => {
          return (
            <div key={idx}>
              <div className='font-bold nomb:text-[24px] mb:text-[20px]'>
                {title1}
              </div>
              <div className='font-bold nomb:text-[24px] mb:text-[20px]'>
                {title2}
              </div>
              <div className='text-[14px] laeding-[160%] nomb:whitespace-pre-line mt-[8px]'>
                {sub}
              </div>
            </div>
          );
        })}
      </div>
      <div className='grid nomb:grid-cols-3 nomb:mt-[80px] mb:mt-[84px] nomb:gap-x-[16px] mb:gap-y-[56px]'>
        {cultureSection3Layouts2.map(({ img, title }, idx) => {
          return (
            <div key={idx}>
              <Desktop>
                <img
                  src={`/assets/culture/content/${img}.png`}
                  srcSet={`/assets/culture/content/${img}@2x.png 2x, /assets/culture/content/${img}@3x.png 3x`}
                  alt=''
                />
              </Desktop>
              <Tablet>
                <img
                  src={`/assets/culture/content/${img}-tablet.png`}
                  srcSet={`/assets/culture/content/${img}-tablet@2x.png 2x, /assets/culture/content/${img}-tablet@3x.png 3x`}
                  alt=''
                />
              </Tablet>
              <Mobile>
                <img
                  src={`/assets/culture/content/${img}-mb.png`}
                  srcSet={`/assets/culture/content/${img}-mb@2x.png 2x, /assets/culture/content/${img}-mb@3x.png 3x`}
                  alt=''
                />
              </Mobile>
              <div className='font-bold pc:text-[20px] tablet:text-[16px] mb:text-[18px] leading-[140%] mt-[16px] whitespace-pre-line'>
                {title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CultureSection3;

const cultureSection3Layouts = [
  {
    title1: "도전(Challenge)",
    title2: "실패를 두려워하기 보다는, 우리가 가진 힘을 믿습니다.",
    sub: `기존의 방식에서 벗어나 두려움 없이 새로운 방향을 모색하며, 실패를 성장의 발판으로 삼아 창의적이고
          진취적인 행동으로 우리 제품과 기업을 발전시킵니다.`,
  },
  {
    title1: "협동(Collaboration)",
    title2: "서로의 강점을 발휘하여 함께 문제를 해결합니다.",
    sub: `협업이란, 팀원들과 함께 지식을 공유하고 공동의 목표를 설정하여 문제를 함께 해결하는 과정입니다.
        이 과정에서 각자의 강점을 인정하고 서로 도와가며, 열린 마음으로 대화하고 신뢰를 바탕으로 올바른 
        결정을 내리는 것을 의미합니다. 이렇게 함으로써 팀 전체가 함께 성장하고 성공할 수 있습니다.`,
  },
  {
    title1: "존중(Respect)",
    title2: "열린 자세와 경청으로 휴컨답게 성장합니다.",
    sub: `우리답게 성공하기 위해서는 더 많은 대화와 피드백을 통해 서로를 이해하고 존중해야 합니다. 
    고객의 피드백과 요구사항에 열린 자세로 대응하고, 휴컨의 문화와 가치를 존중하며 일한다면,
    모두가 함께 성장하고 발전할 수 있습니다.`,
  },
  {
    title1: "책임(Responsibility)",
    title2: "하나된 팀으로 성장하여 보다 더 나은 세상을 만들어 갑니다.",
    sub: `휴컨에서 말하는 책임이란, 개인의 역할을 넘어 팀 전체의 행동이 공동체에 미치는 영향을 고려하며, 
    더 나은 세상을 만드는 데 지속적으로 기여하는 것입니다. 우리는 고객에게 최고의 가치를 제공하는 
    제품과 서비스를 전달하고, 팀으로서 함께 문제를 해결하기 위해 서로의 성장을 지지해주는 든든한 동료가 됩니다.`,
  },
];

const cultureSection3Layouts2 = [
  {
    img: "culture-section3-1",
    title: `끊임없는 도전을 통해 새로운 것을
찾아가는 당신을 응원합니다.`,
  },
  {
    img: "culture-section3-2",
    title: `나와 구성원 그리고 우리 것에 대한
신뢰를 바탕으로 성장합니다.`,
  },
  {
    img: "culture-section3-3",
    title: `우리 서비스와 제품에 대한 책임을 
가지고 각자의 업무를 수행합니다.`,
  },
];
