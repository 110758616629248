import React from "react";

type Props = {
  style: string;
};
// pc:pt-[184px] tablet:pt-[182px] mb:pt-[211px]
const MainSection6 = ({ style }: Props) => {
  return (
    <div className='w-full h-full bg-black '>
      <div
        className={`relative h-full pc:w-[1146px] m-auto flex flex-col nopc:items-center mb:px-5 ${style}`}
      >
        <div className='nomb:w-[1157px] mb:w-[768px] absolute bottom-0 pc:left-[134px]'>
          <img
            className='w-full'
            src='/assets/main/section6/main-section6.png'
            srcSet='/assets/main/section6/main-section6@2x.png 2x, /assets/main/section6/main-section6@3x.png 3x'
            alt='back'
          />
        </div>
        <div className='relative z-10 text-white nopc:text-center mb:w-full'>
          <div className='font-mont nomb:text-[40px] mb:text-[32px] font-bold'>
            CONTACT US
          </div>
          <div className='font-bold nomb:text-2xl mb:text-xl mt-1 nomb:mb-6 mb:mb-[42px]'>
            궁금하신 점이 있나요?
          </div>
          <div className='font-mont nomb:w-[471px] mb:w-full h-[54px] bg-gray500 flex items-center justify-between rounded-[10px] pl-[15px] nomb:pr-[25px] mb:pr-[20px]'>
            <div className='flex gap-x-[7px] items-center'>
              <div className='w-8'>
                <img src='/assets/main/section6/mail.svg' alt='mail' />
              </div>
              <div className='font-bold nomb:text-base mb:text-sm'>MAIL</div>
            </div>
            <div className='nomb:text-[18px] mb:text-sm'>
              huconn@huconn.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection6;
