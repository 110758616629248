import React from "react";
import { Responsiv } from "../../../interface/redux/config";

type Props = {
  Desktop: Responsiv;
  Tablet: Responsiv;
  Mobile: Responsiv;
};

const HistoryContentSection = ({ Desktop, Tablet, Mobile }: Props) => {
  return (
    <div className="relative">
      <div className="nomb:pt-[117px] mb:pt-[174px] pc:w-[1147px] mb:w-full mb:px-5 tablet:w-[688px] m-auto relative z-10">
        <HistoryCircle Desktop={Desktop} Tablet={Tablet} Mobile={Mobile} />
        <span className="font-extrabold nomb:text-base mb:text-sm bg-main">
          기업 연혁
        </span>
        <div className="mt-2 text-white nomb:mb-[45px] mb:mb-[65px] nomb:text-[34px] mb:text-2xl font-bold">
          휴컨은 탄탄한 경험을 통해 <br /> 성장해나가고 있습니다
        </div>
        <button className="nomb:w-[155px] nomb:h-[44px] mb:w-[124px] mb:h-[37px] bg-gradient nomb:text-lg mb:text-base font-bold">
          2019~2024
        </button>
        <div className="w-full bg-gradient h-[2px]" />
        <div className="pt-[70px] pc:pb-[118px] tablet:pb-[147px] mb:pb-[111px] pc:px-[38px] tablet:px-[29px] bg-gray-trans grid pc:gap-y-[80px] nopc:gap-y-[70px]">
          {layout.map(({ year, list }, idx) => {
            return (
              <div
                key={idx}
                className="flex w-full mb:items-center mb:flex-col"
              >
                <span className="font-medium font-mont bg-main h-fit text-[40px] nomb:w-[100px]">
                  {year}
                </span>
                <div className="nomb:mx-[18px] mb:my-[20px] flex flex-col items-center nomb:pt-[20px]">
                  <div className="w-[17px] h-[17px] rounded-[50%] bg-mint" />
                  <div className="nomb:h-full mb:h-[108px] bg-mint w-[3px]" />
                </div>
                <div className="nomb:flex-1 pt-[5px]">
                  {list.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className="w-full text-white nomb:h-[48px] border-b-gray600 border-b-[1px] flex items-center nomb:text-base mb:text-sm mb:text-center mb:flex mb:justify-center mb:py-3 mb:whitespace-pre-line mb:leading-[160%]"
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="pc:mt-[264px] tablet:mt-[282px] mb:mt-[271px] pc:pb-[203px] tablet:pb-[187px] mb:pb-[100px] mb:flex mb:flex-col mb:items-center">
          <span className="text-base font-extrabold bg-main">인증 및 특허</span>
          <div className="mt-2 text-white mb-[45px] nomb:text-[34px] mb:text-2xl font-bold mb:text-center">
            휴컨은 믿을 수 있는
            <br /> 인증된 기업입니다
          </div>
          <Desktop>
            <img
              className="w-[1185px] max-w-none translate-x-[-20px]"
              src="/assets/about/history/content/history-content-pc.png"
              srcSet="/assets/about/history/content/history-content-pc@2x.png 2x, /assets/about/history/content/history-content-pc@3x.png 3x"
              alt=""
            />
          </Desktop>
          <Tablet>
            <img
              className="w-[727px] max-w-none translate-x-[-20px]"
              src="/assets/about/history/content/history-content-tablet.png"
              srcSet="/assets/about/history/content/history-content-tablet@2x.png 2x, /assets/about/history/content/history-content-tablet@3x.png 3x"
              alt=""
            />
          </Tablet>
          <Mobile>
            <img
              src="/assets/about/history/content/history-content-mb.png"
              srcSet="/assets/about/history/content/history-content-mb@2x.png 2x, /assets/about/history/content/history-content-mb@3x.png 3x"
              alt=""
            />
          </Mobile>
        </div>
      </div>
      <div className="w-full h-[620px] absolute bottom-0 bg-history-gradient" />
    </div>
  );
};

export default HistoryContentSection;

const HistoryCircle = ({ Desktop, Tablet, Mobile }: Props) => {
  return (
    <>
      <Desktop>
        <>
          <img
            className=" absolute w-[616px] top-[0] right-[-468px]"
            src="/assets/about/history/content/history-circle-1-pc.png"
            srcSet="/assets/about/history/content/history-circle-1-pc@2x.png 2x, /assets/about/history/content/history-circle-1-pc@3x.png 3x"
            alt=""
          />
          <img
            className="absolute top-[449px] left-[-196px] w-[405px]"
            src="/assets/about/history/content/history-circle-2-pc.png"
            srcSet="/assets/about/history/content/history-circle-2-pc@2x.png 2x, /assets/about/history/content/history-circle-2-pc@3x.png 3x"
            alt=""
          />
        </>
      </Desktop>
      <Tablet>
        <>
          <img
            className=" absolute w-[430px] top-[107px] right-[-247px]"
            src="/assets/about/history/content/history-circle-1-tablet.png"
            srcSet="/assets/about/history/content/history-circle-1-tablet@2x.png 2x, /assets/about/history/content/history-circle-1-tablet@3x.png 3x"
            alt=""
          />
          <img
            className="absolute top-[557px] left-[-120px] w-[353px]"
            src="/assets/about/history/content/history-circle-2-tablet.png"
            srcSet="/assets/about/history/content/history-circle-2-tablet@2x.png 2x, /assets/about/history/content/history-circle-2-tablet@3x.png 3x"
            alt=""
          />
        </>
      </Tablet>
      <Mobile>
        <>
          <img
            className=" absolute w-[270px] top-[223px] right-[0]"
            src="/assets/about/history/content/history-circle-1-pc.png"
            srcSet="/assets/about/history/content/history-circle-1-pc@2x.png 2x, /assets/about/history/content/history-circle-1-pc@3x.png 3x"
            alt=""
          />
          <img
            className="absolute top-[539px] left-[-60px] w-[270px]"
            src="/assets/about/history/content/history-circle-2-pc.png"
            srcSet="/assets/about/history/content/history-circle-2-pc@2x.png 2x, /assets/about/history/content/history-circle-2-pc@3x.png 3x"
            alt=""
          />
        </>
      </Mobile>
    </>
  );
};

const layout = [
  {
    year: 2024,
    list: [
      '차량용 디지털 클러스터 제어 시스템',
      '차량 디지털 클러스터 장치'
    ],
  },
  {
    year: 2023,
    list: [
      'ESS 관리 시스템(ESS MANAGEMENT SYSTEM)',
      '배터리 관리 시스템(BATTERY MANAGEMENT SYSTEM)',
      '차량용 배터리 관리시스템의 펌웨어 업데이트 장치 및 그 방법',
      '차량 인포테인먼트 통합형 디지털 클러스터 시스템',
      '주차시설과 연계된 이동식 충전장치 기반의 전기차 충전관리 시스템 및 그 방법',
      '차량용 오픈플랫폼 기반 인포테인먼트 서비스 제공을 위한 이더넷 통신 방법'
    ],
  },
  {
    year: 2022,
    list: [
      `이동형 전기차 충전장치의
배터리팩 관리시스템`,
      `이동형 충전장치의 배터리 관리시스템
및 배터리 관리방법`,
      `차량용 전장 시스템의 OS 동작방법`,
      `차량용 인포테인먼트를 통한
개인화 서비스 제공시스템`,
      `배터리 노화를 고려한 SOC 추정 방법`,
    ],
  },
  {
    year: 2021,
    list: [
      `IEC62619 KTL 인증 (중소기업 최초)`,
      `AutoSAR code gen tool 개발
(DGIST 협력)`,
      `DGIST 기술 이전
(전기차 충전기 관리 기술)`,
      `골프카트용 48V 배터리 팩 개발`,
      `초소형 전기차용 CAN SW stack 개발
(DGIST 협력)`,
      `말레이시아 Faurecia Clarion
인포테인먼트 개발(GT시스템 협력)`,
      `특허 4건 출원`,
    ],
  },
  {
    year: 2020,
    list: [
      `고안전성 BMS 개발
(NIPA R&D 사업)`,
      `IEC62619 기반 BMS 개발`,
      `자동차 사용후 배터리용 BMS 개발 사업
(지역특화산업육성사업)`,
      `이동형 전기차 충전 장치 개발 사업
(중소벤처기업부)`,
      `BLE RVC 헤드유닛 개발`,
    ],
  },
  {
    year: 2019,
    list: [
      `인도 Mahindra&Mahindra 
인포테인먼트 개발(HUMAX 협력)`,
      `브라질 CAOA 인포테인먼트 
개발(HUMAX 협력)`,
      `특허 2건 출원(휴대폰 거치형 인포테인먼트)`,
      `배터리 관리 시스템 개발 (파워뱅크)`,
    ],
  },
];
