import React from "react";

type Props = {};

const Footer = (props: Props) => {
  return (
    <footer className="nomb:h-[314px] mb:h-[377px] pt-[75px] nomb:pl-[56px]  w-full bg-gray600 text-white text-[13px] mb:flex mb:flex-col mb:items-center">
      <div className="mb-4 w-[122px] h-[25px]">
        <img src="/assets/footer/logo.svg" alt="huconn" />
      </div>
      <div className="flex nomb:gap-x-[26px] mb:gap-y-[17px] mb:flex-col mb:items-center mb:text-center">
        <div>
          (주)휴컨 | 대표 강대근 | 사업자등록번호 463-88-00285 <br /> 약관 및
          정책
        </div>
        <div className="grid gap-y-[7px]">
          {layout.map(({ asset, content }, idx) => {
            return (
              <div key={idx} className="flex gap-x-[5px] items-center">
                <div>
                  <img src={`/assets/footer/${asset}.svg`} alt={asset} />
                </div>
                <div>{content}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-9">Copyright HUCONN, All Rights Reserved</div>
    </footer>
  );
};

export default Footer;

const layout = [
  {
    asset: "place",
    content: "대구 동구 동대구로 465, 810호",
  },
  {
    asset: "tel",
    content: "053-341-2613  |  FAX  053-341-2627",
  },
  {
    asset: "mail",
    content: "huconn@huconn.com",
  },
];
