import React from "react";
import { ResponsiveType } from "../../interface/redux/config";

type Props = {
  responsive: ResponsiveType;
};

const CultureSection3 = ({
  responsive: { Desktop, Mobile, Tablet },
}: Props) => {
  return (
    <div className='text-white mt-[240px]'>
      <div className='font-bold nomb:text-[40px] mb:text-[30px] leading-[160%]'>
        휴컨은, <br />
        우리만의 Value를{" "}
        <Mobile>
          <br />
        </Mobile>{" "}
        중심으로 일을 합니다.
      </div>
      <div className='mt-[80px] grid gap-y-[48px]'>
        {cultureSection3Layouts.map(({ title, sub }, idx) => {
          return (
            <div key={idx}>
              <div className='font-bold nomb:text-[24px] mb:text-[20px]'>
                {title}
              </div>
              <div className='text-[14px] laeding-[160%] nomb:whitespace-pre-line mt-[8px]'>
                {sub}
              </div>
            </div>
          );
        })}
      </div>
      <div className='grid nomb:grid-cols-3 nomb:mt-[80px] mb:mt-[84px] nomb:gap-x-[16px] mb:gap-y-[56px]'>
        {cultureSection3Layouts2.map(({ img, title }, idx) => {
          return (
            <div key={idx}>
              <Desktop>
                <img
                  src={`/assets/culture/content/${img}.png`}
                  srcSet={`/assets/culture/content/${img}@2x.png 2x, /assets/culture/content/${img}@3x.png 3x`}
                  alt=''
                />
              </Desktop>
              <Tablet>
                <img
                  src={`/assets/culture/content/${img}-tablet.png`}
                  srcSet={`/assets/culture/content/${img}-tablet@2x.png 2x, /assets/culture/content/${img}-tablet@3x.png 3x`}
                  alt=''
                />
              </Tablet>
              <Mobile>
                <img
                  src={`/assets/culture/content/${img}-mb.png`}
                  srcSet={`/assets/culture/content/${img}-mb@2x.png 2x, /assets/culture/content/${img}-mb@3x.png 3x`}
                  alt=''
                />
              </Mobile>
              <div className='font-bold pc:text-[20px] tablet:text-[16px] mb:text-[18px] leading-[140%] mt-[16px] whitespace-pre-line'>
                {title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CultureSection3;

const cultureSection3Layouts = [
  {
    title: "도전(Challenge)을 혁신의 방법 중 첫 번째라고 생각합니다.",
    sub: `기존의 방식에서 벗어나 새로운 방향을 찾고 새로운 것에 대해 두려워하지 않고,
우리들이 가진 힘을 믿는 것을 도전이라고 생각합니다.
우리는 이런 도전(Challenge)을 통해 창의적인 생각과 진취적인 행동으로 우리 제품과 기업을 성장 시키고자 합니다.`,
  },
  {
    title: "구성원과 우리 것에 대한 신뢰(Trust)를 중요시 합니다.",
    sub: `휴컨의 구성원들은 서로를 신뢰하고 개인과 조직의 실패를 부끄러워하지 않습니다.
실패는 성장의 발판이기 때문입니다.
우리는 신뢰를 바탕으로 서로 대화하며 올바른 결정을 하고자 합니다. `,
  },
  {
    title: "책임(Responsibility)을 가지고 업무를 수행합니다.",
    sub: `휴컨 구성원들은 자신이 맡은 역할과 행동에 따른 책임 의식을 가지고 업무를 수행합니다.
공동의 목표를 가진 공동체와 동료를 위해 책임감을 가져야 합니다.
마지막으로 휴컨 구성원은 우리가 제공하는 서비스와 제품에 대한 책임감을 가집니다.`,
  },
];

const cultureSection3Layouts2 = [
  {
    img: "culture-section3-1",
    title: `끊임없는 도전을 통해 새로운 것을
찾아가는 당신을 응원합니다.`,
  },
  {
    img: "culture-section3-2",
    title: `나와 구성원 그리고 우리 것에 대한
신뢰를 바탕으로 성장합니다.`,
  },
  {
    img: "culture-section3-3",
    title: `우리 서비스와 제품에 대한 책임을 
가지고 각자의 업무를 수행합니다.`,
  },
];
